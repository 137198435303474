import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PostPreview from "../components/PostPreview"
import ArchivePagination from "../components/ArchivePagination"

const Archive = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
    },
    pageContext: {  archivePath },
  } = props

  return (
    <Layout
      bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <GatsbySeo 
        title="Tarjoukset | nettikasinolla.com" description="nettikasinolla.com tarjoukset"
        canonical={`https://www.nettikasinolla.com/`}
      />
      {nodes &&
      nodes.map((post, index) => {
        return (
          <PostPreview
            key={index}
            post={post}
            isLast={index === nodes.length - 1}
          />
        )
      })}

      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: {
          node: { databaseId: { eq: $userDatabaseId } }}
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        content
        databaseId
        date (formatString: "DD.MM.YYYY")
        excerpt
        seo {
          metaDesc
          title
        }
        uri
        featuredImage {
          node {
            id
            mediaItemUrl
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
  }
`

export default Archive
